import * as i0 from '@angular/core';
import { InjectionToken, inject, Injectable } from '@angular/core';
import { Logger, initLogLevel } from '@bannerflow/sentinel-logger';
import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';
const SENTINEL_NR_OPTIONS_TOKEN = new InjectionToken('NewRelicOptionToken');
class NewRelicService {
  constructor() {
    this.browserAgentOptions = inject(SENTINEL_NR_OPTIONS_TOKEN);
    this.setOptions();
  }
  async setOptions() {
    const options = this.browserAgentOptions;
    if (!options.enabled) {
      return;
    }
    const browserAgentOptions = {
      init: {
        session_replay: {
          enabled: options.sessionReplay?.enabled ?? true,
          collect_fonts: true,
          inline_images: true,
          block_selector: '',
          mask_text_selector: options.sessionReplay?.maskSelector ?? '*',
          sampling_rate: options.sessionReplay?.samplingRate ?? 0,
          error_sampling_rate: options.sessionReplay?.errorSamplingRate ?? 1,
          mask_all_inputs: false,
          mask_input_options: {
            color: false,
            date: false,
            'datetime-local': false,
            email: true,
            month: false,
            number: false,
            range: false,
            search: false,
            tel: true,
            text: false,
            time: false,
            url: false,
            week: false,
            textarea: false,
            select: false
          }
        },
        performance: {
          capture_marks: true,
          capture_measures: true,
          capture_detail: true,
          resources: {
            enabled: false,
            asset_types: [],
            first_party_domains: [],
            ignore_newrelic: true
          },
          ...options.performance
        },
        session_trace: {
          enabled: true,
          autoStart: true
        },
        distributed_tracing: {
          enabled: true,
          exclude_newrelic_header: true,
          cors_use_tracecontext_headers: true,
          allowed_origins: Array.from(new Set(options.origins.map(origin => {
            const url = new URL(origin);
            return `${url.protocol}//${url.hostname}`;
          })))
        },
        privacy: {
          cookies_enabled: true
        },
        ajax: {
          deny_list: ['bam.eu01.nr-data.net', 'aptrinsic.com', 'esp-eu.aptrinsic.com']
        }
      },
      info: {
        beacon: 'bam.eu01.nr-data.net',
        errorBeacon: 'bam.eu01.nr-data.net',
        licenseKey: options.licenseKey,
        applicationID: options.applicationId,
        sa: 1
      },
      loader_config: {
        accountID: options.accountId,
        trustKey: options.trustKey,
        agentID: options.agentId,
        licenseKey: options.licenseKey,
        applicationID: options.applicationId
      }
    };
    this.browserAgent = new BrowserAgent(browserAgentOptions);
    this.browserAgent.setApplicationVersion(options.applicationVersion);
    this.browserAgent.addRelease(options.releaseName, options.releaseId);
    this.browserAgent.setCustomAttribute('browserWidth', window.innerWidth);
    this.browserAgent.setCustomAttribute('browserHeight', window.innerHeight);
    this.browserAgent.setCustomAttribute('screenWidth', window.screen.width);
    this.browserAgent.setCustomAttribute('screenHeight', window.screen.height);
    this.browserAgent.setErrorHandler(error => {
      const ignoredErrors = this.browserAgentOptions.ignoredErrors;
      if (typeof ignoredErrors === 'function') {
        return ignoredErrors(error);
      }
      return this.errorHandler(error);
    });
  }
  errorHandler(error) {
    const ignoredErrors = this.browserAgentOptions.ignoredErrors;
    if (typeof ignoredErrors === 'function' || !ignoredErrors?.length) {
      return false;
    }
    const errorMessage = error instanceof Error ? error.message : error;
    const errorName = error instanceof Error ? error.name : undefined;
    for (const ignoredError of ignoredErrors) {
      if (ignoredError === errorName || ignoredError === errorMessage) {
        return true;
      }
    }
    return false;
  }
  /**
   * Set the user id and account attribute, useful for tying all browser events to specific users
   */
  setUser(userId, accountSlug) {
    if (!this.browserAgent) {
      return;
    }
    this.browserAgent.setUserId(userId);
    this.browserAgent.setCustomAttribute('account', accountSlug);
  }
  noticeError(error, context) {
    if (!this.browserAgent) {
      return;
    }
    this.browserAgent.noticeError(error, context);
  }
  addPageAction(name, data) {
    if (!this.browserAgent) {
      return;
    }
    this.browserAgent.addPageAction(name, {
      data
    });
  }
  startTrace(name, data = {}) {
    performance.mark(name, {
      detail: data
    });
  }
  finishTrace(traceName, data = {}) {
    performance.measure(traceName, {
      start: traceName,
      detail: data
    });
  }
  /**
   * The returned `InteractionInstance` can be composed with additional SPA APIs. See documentation:
   * https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/interaction/
   */
  trackInteraction(message, contexts) {
    if (!this.browserAgent) {
      return;
    }
    return this.browserAgent.interaction().setAttribute(message, contexts);
  }
  static {
    this.ɵfac = function NewRelicService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NewRelicService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NewRelicService,
      factory: NewRelicService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NewRelicService, [{
    type: Injectable
  }], () => [], null);
})();
class SentinelService {
  constructor() {
    this.newRelicService = inject(NewRelicService, {
      optional: true
    });
    this.logger = new Logger('Sentinel');
    this.warn = this.logger.warn.bind(this.logger);
    this.log = this.logger.log.bind(this.logger);
    this.debug = this.logger.debug.bind(this.logger);
    this.verbose = this.logger.verbose.bind(this.logger);
  }
  /**
   * Set the user id and account attribute, useful for tying all browser events to specific users
   */
  setUser(userId, accountSlug) {
    if (!this.newRelicService) {
      return;
    }
    this.newRelicService.setUser(userId, accountSlug);
  }
  error(error, context) {
    this.logger.error(error);
    if (!this.newRelicService) {
      return;
    }
    this.newRelicService.noticeError(error, context);
  }
  trackPageAction(name, data) {
    if (!this.newRelicService) {
      return;
    }
    this.newRelicService.addPageAction(name, {
      data
    });
  }
  startTrace(traceName, data = {}) {
    if (!this.newRelicService) {
      return;
    }
    this.newRelicService.startTrace(traceName, data);
  }
  finishTrace(traceName, data = {}) {
    if (!this.newRelicService) {
      return;
    }
    this.newRelicService.finishTrace(traceName, data);
  }
  /**
   * The returned `InteractionInstance` can be composed with additional SPA APIs. See documentation:
   * https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/interaction/
   */
  trackInteraction(message, contexts) {
    if (!this.newRelicService) {
      return;
    }
    return this.newRelicService.trackInteraction(message, contexts);
  }
  static {
    this.ɵfac = function SentinelService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SentinelService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SentinelService,
      factory: SentinelService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SentinelService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
function provideSentinelService(...sentinelFeatures) {
  return [SentinelService, ...sentinelFeatures];
}
/** Sets configuration for the Logger class on a global level */
function withLoggerConfig(logLevelOptions) {
  initLogLevel(logLevelOptions);
  return []; // Workaround to not have angular complain about tokens
}
/** Provides required dependencies for New Relic */
function withNewRelic(options) {
  return [NewRelicService, {
    provide: SENTINEL_NR_OPTIONS_TOKEN,
    useValue: {
      enabled: true,
      ...options
    }
  }];
}

/*
 * Public API Surface of sentinel
 */

/**
 * Generated bundle index. Do not edit.
 */

export { SentinelService, provideSentinelService, withLoggerConfig, withNewRelic };
