import { a as r } from "./chunk-CBAJ6JPH.js";
import { a as v, d as m, e as l, f as g, g as f } from "./chunk-XGQN5FSU.js";
var b = class n {
  constructor(e, t = !1) {
    this.context = e;
    this.isTimestampEnabled = t;
  }
  static {
    this._logLevel = 4;
  }
  static get LogLevel() {
    return n._logLevel;
  }
  static {
    this.instance = n;
  }
  group(e, t) {
    n.isLogLevelEnabled(2) && this.callFunction("group", e, t);
  }
  groupEnd() {
    n.isLogLevelEnabled(2) && n.groupEnd();
  }
  log(e, t = this.context) {
    this.callFunction("log", e, t);
  }
  debug(e, t = this.context) {
    this.callFunction("debug", e, t);
  }
  verbose(e, t = this.context) {
    this.callFunction("verbose", e, t);
  }
  warn(e, t = this.context) {
    this.callFunction("warn", e, t);
  }
  error(e, t = "", i = this.context) {
    let o = this.getInstance();
    o && o.error.call(o, e, t, i, this.isTimestampEnabled);
  }
  setContext(e) {
    this.context = e;
  }
  static getLogLevelName() {
    switch (n._logLevel) {
      case 0:
        return "DEBUG";
      case 2:
        return "LOG";
      case 1:
        return "VERBOSE";
      case 3:
        return "WARNING";
      case 4:
        return "ERROR";
      default:
        return `${n._logLevel} not handled`;
    }
  }
  static setLogLevel(e) {
    n._logLevel = e;
  }
  getTimestamp() {
    return n.getTimestamp();
  }
  static group(e) {
    console.groupCollapsed(e);
  }
  static groupEnd() {
    console.groupEnd();
  }
  static isLogLevelEnabled(e) {
    return e >= n._logLevel;
  }
  static log(e, t = "", i = !0) {
    n.isLogLevelEnabled(2) && this.printMessage("log", e, void 0, t, i);
  }
  static debug(e, t = "", i = !0) {
    n.isLogLevelEnabled(0) && this.printMessage("debug", e, r.blue, t, i);
  }
  static verbose(e, t = "", i = !0) {
    n.isLogLevelEnabled(1) && this.printMessage("log", e, r.cyanBright, t, i);
  }
  static warn(e, t = "", i = !0) {
    n.isLogLevelEnabled(3) && this.printMessage("warn", e, r.yellow, t, i);
  }
  static error(e, t = "", i = "", o = !0) {
    n.isLogLevelEnabled(4) && (this.printMessage("error", e, r.red, i, o), this.printStackTrace(t));
  }
  static getTimestamp() {
    return v(Date.now());
  }
  callFunction(e, t, i) {
    let o = this.getInstance(),
      s = o && o[e];
    s && s.call(o, t, i || this.context, this.isTimestampEnabled);
  }
  getInstance() {
    let {
      instance: e
    } = n;
    return e === this ? n : e;
  }
  static printMessage(e, t, i, o = "", s) {
    let a = t;
    l(t) ? a = `Object:
${JSON.stringify(t, null, 2)}
` : g(t) && (a = t.message);
    let c = o ? r.yellow : "",
      E = o ? `%c[${o}] ` : "",
      d = this.updateAndGetTimestampDiff(s),
      p = d.length ? r.white : "",
      u = this.instance ?? n,
      L = `${u.getTimestamp ? u.getTimestamp() : n.getTimestamp?.()} ${E} %c${a} ${d}
`;
    m(t) && !l(t) ? (n.logMessage("groupCollapsed", L, c, i, p), g(t) || f(t) ? console.error(t) : console.dir(t), console.groupEnd()) : n.logMessage(e, L, c, i, p);
  }
  static logMessage(e, t, i, o, s) {
    o = e === "warn" || e === "error" ? void 0 : o, i ? console[e](t, i, o, s) : console[e](t, o, s);
  }
  static updateAndGetTimestampDiff(e) {
    let t = "";
    return n.lastTimestamp && e ? (t = `%c +${Date.now() - n.lastTimestamp}ms `, n.lastTimestamp = Date.now(), t) : (n.lastTimestamp = Date.now(), t);
  }
  static printStackTrace(e) {
    e && console.error(e);
  }
};
export { b as a };