import { Environment, PasswordConnectionHint } from './environment.type';

export const environment: Environment = {
    NAME: 'sandbox',
    LOGIN_URL: 'https://sandbox-login.bannerflow.com',
    CLIENT_ID: 'v4BaydXyP8yq7RGUBKhYoB8wlEXF3uZC',
    BANNEFLOW_CLIENT_URL: 'https://sandbox-home.bannerflow.com',
    ACCOUNT_ACCESS_SERVICE_URL: 'https://sandbox-api.bannerflow.com/account-access',
    BAU_SERVICE_URL: 'https://sandbox-api.bannerflow.com/bau',
    CONNECTION_HINT_MAP: new Map<string, string>([
        [PasswordConnectionHint, 'Username-Password-Authentication'],
        ['bfg', 'bannerflow-google'],
        ['bfe', 'bannerflow-entra']
    ]),
    NEW_RELIC_ACCOUNT_ID: '4232543',
    NEW_RELIC_APPLICATION_ID: '538687567',
    NEW_RELIC_LICENSE_KEY: 'NRBR-3f0570f4ed63339c8d0',
    NEW_RELIC_TRUST_KEY: '4122654',
    RELEASE_NAME: '',
    VERSION: ''
};
