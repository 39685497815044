import { Environment, PasswordConnectionHint } from './environment.type';

export const environment: Environment = {
    LOGIN_URL: 'https://sandbox-login.bannerflow.com',
    CLIENT_ID: 'v4BaydXyP8yq7RGUBKhYoB8wlEXF3uZC',
    BANNEFLOW_CLIENT_URL: 'https://sandbox-home.bannerflow.com',
    ACCOUNT_ACCESS_SERVICE_URL: 'https://sandbox-api.bannerflow.com/account-access',
    BAU_SERVICE_URL: 'https://sandbox-api.bannerflow.com/bau',
    CONNECTION_HINT_MAP: new Map<string, string>([
        [PasswordConnectionHint, 'Username-Password-Authentication'],
        ['bfg', 'bannerflow-google'],
        ['bfe', 'bannerflow-entra']
    ])
};
